import Vue from "vue";
import titleMixin from "@/mixins/title";
import spinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [titleMixin, spinnerMixin],
    data() {
        return {
            id: this.$route.params.id,
            title: this.$t("customersTimeSlots.edit.title", { name: "" }),
            data: {},
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    },
                    startTime: {
                        required: true
                    },
                    endTime: {
                        required: true,
                        dateAfter: [
                            (args) => {
                                const startTime = args.element.form.querySelector(`#startTime`)
                                    .ej2_instances[0].value;
                                const endTime = args.element.form.querySelector(`#endTime`)
                                    .ej2_instances[0].value;
                                return startTime < endTime;
                            },
                            this.$t("dailyWorkAvailability.endTimeMustBeGreaterThanStartTime")
                        ]
                    },
                    order: {
                        required: true,
                        min: 0
                    }
                }
            }
        };
    },
    methods: {
        edit() {
            return this.$store.dispatch("customerTimeSlot/edit", {
                id: this.id,
                timeSlot: this.data
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("customersTimeSlots.edit.toastSuccessTitle"), this.$t("customersTimeSlots.edit.toastSuccessContent", {
                name: this.data.name
            }));
            return this.$router.push("/customers-time-slots");
        },
        cancel() {
            return this.$router.push("/customers-time-slots");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.data = await this.$store.dispatch("customerTimeSlot/get", {
                id: this.id
            });
            this.title = this.$t("customersTimeSlots.edit.title", { name: `${this.data.name}` });
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/customers-time-slots");
        }
        finally {
            this.hideSpinner();
        }
    }
});
